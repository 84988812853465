<template>
  <v-app>
    <v-card width="500px" class="mx-auto my-auto">
      <v-toolbar color="#0673b2" flat dark>
        <v-img
          max-height="190"
          max-width="170"
          src="../assets/strong-golf.png"
        ></v-img>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-model="email"
          prepend-icon="mdi-email"
          label="メールアドレス"
        />
        <v-text-field
          v-model="password"
          prepend-icon="mdi-lock"
          label="パスワード"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
        <v-card-actions>
          <v-btn block color="#0673b2" dark @click='login' :loading="loading">
            <v-icon left>mdi-login-variant</v-icon>
            LOGIN
          </v-btn>
        </v-card-actions>
        <div class="mt-6 text-center">
          <v-btn text @click="dialog = true">パスワードを忘れた方はこちら</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <password-reset v-model="dialog"></password-reset>
  </v-app>
</template>

<script>
import PasswordReset from '../components/PasswordReset';

export default {
  components: {
    PasswordReset,
  },
  name: 'login',
  data: () => ({
    showPassword: false,
    email: '',
    password: '',
    loading: false,
    dialog: false,
  }),
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
    redirectPath() {
      let path = '/login';
      switch (this.userInfo.roll) {
        case 'admin':
          path = '/reservation';
          break;
        case 'member':
          path = '/entry';
          break;
        case 'instructor':
          path = '/reservation';
          break;
        default:
      }
      return path;
    },
  },
  methods: {
    async login() {
      this.loading = true;
      await this.$store.dispatch('singIn', { email: this.email, password: this.password })
        .then(() => {
          this.loading = false;
          this.$router.push(this.redirectPath);
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit('snackbar/open', { text: 'メールアドレス または パスワード が違います。', color: 'error' });
        });
    },
  },
};
</script>
