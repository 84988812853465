<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="640">
    <v-card v-if='!mailSended' width="640px" class="mx-auto my-auto">
      <v-card-title>
        <span class='text-h6'>パスワード再設定</span>
      </v-card-title>
      <v-card-text>
        <p>登録されたメールアドレスを入力後<span class='font-weight-bold'> [パスワードを再設定する] </span>ボタンを押してください。</p>
        <v-text-field
          v-model="email"
          prepend-icon="mdi-email"
          label="メールアドレス"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined @click="dialog = false" :disabled="loading">
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn color='#0673b2' dark @click="passwordReset" :loading="loading">
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          パスワードを再設定する
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else width="640px" class="mx-auto my-auto">
      <v-card-title>
        <span class='text-h6'>パスワード再設定用のメールを送信しました。</span>
      </v-card-title>
      <v-card-text>
        <p>メールが届かない場合は、迷惑メールフォルダやゴミ箱に自動的に振り分けられている可能性がありますので、一度ご確認頂きますようお願い致します。</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined @click="dialog = false" :disabled="loading">
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'password-reset',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    email: '',
    loading: false,
    dialog: false,
    mailSended: false,
  }),
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
    redirectPath() {
      let path = '/login';
      switch (this.userInfo.roll) {
        case 'admin':
          path = '/reservation';
          break;
        case 'member':
          path = '/entry';
          break;
        case 'instructor':
          path = '/reservation';
          break;
        default:
      }
      return path;
    },
  },
  watch: {
    async dialog() {
      this.$emit('input', this.dialog);
      if (!this.dialog) {
        this.mailSended = false;
      }
    },
    async value() {
      this.dialog = this.value;
    },
  },
  methods: {
    async login() {
      this.loading = true;
      await this.$store.dispatch('singIn', { email: this.email, password: this.password })
        .then(() => {
          this.loading = false;
          this.$router.push(this.redirectPath);
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit('snackbar/open', { text: 'メールアドレス または パスワード が違います。', color: 'error' });
        });
    },
    async passwordReset() {
      this.loading = true;
      const auth = getAuth();
      const actionCodeSettings = {
        // パスワード再設定後のリダイレクト URL
        url: `${process.env.VUE_APP_DOMAIN}/#/login`,
        handleCodeInApp: false,
      };
      await sendPasswordResetEmail(auth, this.email, actionCodeSettings)
        .then(() => {
          this.mailSended = true;
          this.loading = false;
          // メール送信成功
        })
        .catch(() => {
          this.loading = false;
          // メール送信失敗
          this.$store.commit('snackbar/open', { text: 'メールが送信できませんでした。', color: 'error' });
        });
    },
  },
};
</script>
